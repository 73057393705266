// automatisation de l'activation de la video===================================

window.onload = function() {
  var dateActuelle = new Date();
  var dateLimite = new Date('2024-02-28T07:00:00'); 

  if (dateActuelle >= dateLimite) {
      var videoContainer = document.querySelector('.video-container');
      if (videoContainer) {
          videoContainer.style.display = "flex";
      }
  }
};